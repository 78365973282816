define("discourse/plugins/swapd-close-topic/discourse/templates/connectors/topic-footer-main-buttons-before-create/custom-close-topic", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.custom_close_topic_enabled}}
    {{#if this.args.outletArgs.topic.custom_can_close_topic}}
      <DButton
        @action={{close}}
        @label="custom_close_topic.close"
        @disabled={{loading}}
        @icon="lock"
      />
    {{/if}}
  {{/if}}
  */
  {
    "id": "rTLx7SEV",
    "block": "[[[41,[30,0,[\"siteSettings\",\"custom_close_topic_enabled\"]],[[[41,[30,0,[\"args\",\"outletArgs\",\"topic\",\"custom_can_close_topic\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@action\",\"@label\",\"@disabled\",\"@icon\"],[[30,0,[\"close\"]],\"custom_close_topic.close\",[30,0,[\"loading\"]],\"lock\"]],null],[1,\"\\n\"]],[]],null]],[]],null],[1,[28,[32,0],[\"[[\\\"The `close` property path was used in the `discourse/plugins/swapd-close-topic/discourse/templates/connectors/topic-footer-main-buttons-before-create/custom-close-topic.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.close}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `loading` property path was used in the `discourse/plugins/swapd-close-topic/discourse/templates/connectors/topic-footer-main-buttons-before-create/custom-close-topic.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.loading}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"d-button\"]]",
    "moduleName": "discourse/plugins/swapd-close-topic/discourse/templates/connectors/topic-footer-main-buttons-before-create/custom-close-topic.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});