define("discourse/plugins/swapd-close-topic/discourse/connectors/topic-footer-main-buttons-before-create/custom-close-topic", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/service"], function (_exports, _component, _object, _tracking, _ajax, _ajaxError, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let MyComponent = _exports.default = (_class = class MyComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
      _initializerDefineProperty(this, "dialog", _descriptor2, this);
    }
    close() {
      this.dialog.alert({
        message: I18n.t("custom_close_topic.message"),
        buttons: [{
          icon: "lock",
          label: I18n.t("custom_close_topic.just_close"),
          class: "btn-default",
          action: () => this.customClose()
        }, {
          icon: "dollar-sign",
          label: I18n.t("custom_close_topic.close_mark_sold"),
          class: "btn-danger",
          action: () => this.customClose(true)
        }, {
          label: I18n.t("composer.cancel")
        }]
      });
    }
    customClose(mark_sold) {
      this.loading = true;
      (0, _ajax.ajax)(`/t/${this.args.outletArgs.topic.id}/custom_close`, {
        type: "POST",
        data: {
          mark_sold
        }
      }).then(data => {
        location.reload();
      }).catch(_ajaxError.popupAjaxError).finally(() => this.loading = false);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "customClose", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "customClose"), _class.prototype)), _class);
});